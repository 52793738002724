<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10">
              <CInput
                :placeholder="$t('search_a') + $t('title')"
                v-model="searchData.title"
                v-on:keyup.enter="getPosts()"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getPosts()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createPostModal = { modal: true }">{{ $t('create_post') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <font slot="created_at" slot-scope="props">
              {{ $moment(props.row.created_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editPostModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deletePostModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreatePostModal :data="createPostModal.data" :show="createPostModal.modal" @showUpdate="show => createPostModal.modal = show" />

      <EditPostModal :data="editPostModal.data" :show="editPostModal.modal" @showUpdate="show => editPostModal.modal = show" />

      <DeletePostModal :data="deletePostModal.data" :show="deletePostModal.modal" @showUpdate="show => deletePostModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import CreatePostModal from './modal/CreatePostModal'
import EditPostModal from './modal/EditPostModal'
import DeletePostModal from './modal/DeletePostModal'

export default {
  name: 'posts',
  components: {
    CreatePostModal,
    EditPostModal,
    DeletePostModal,
  },
  data () {
    return {
      columns: [ 'title_en', 'title_zh', 'enabled', 'created_at', 'action' ],
      data: [],
      options: {
        headings: {
          title_en: this.$t('title') + ' (EN)',
          title_zh: this.$t('title') + ' (ZH)',
          enabled: this.$t('enable'),
          created_at: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'title_en', 'title_zh', 'enabled', 'created_at' ],
        filterable: [ 'title_en', 'title_zh' ]
      },
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { title: '' },
      createPostModal: { data: {}, modal: false },
      editPostModal: { data: {}, modal: false },
      deletePostModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    getPosts () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getPosts', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
