<template>
  <CModal
    :title="$t('create_post')"
    size="xl"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CTabs :fill="true" variant="tabs" :fade="false" :active-tab="0" addNavWrapperClasses="pb-4">
      <CTab title="EN">
        <CRow>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-3">
                {{ $t('image') }}
              </label>
              <div class="col-sm-9">
                <img :src="formData.image_url || 'img/no-img.png'" class="shadow rounded img-cursor" height="200" @click="$refs.uploadImage.click()" />
                <input type="file" ref="uploadImage" style="position: absolute;clip: rect(0 0 0 0);" accept="image/png, image/jpeg, image/jpg" @change="imageCropper">
              </div>
            </div>
          </CCol>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('title') + ' (EN)'"
              :placeholder="$t('title') + ' (EN)'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="formData.title_en"
              :isValid="valid_formData_title_en"
            />
          </CCol>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-12">
                {{ $t('preview') + ' (EN)' }}
              </label>
              <div class="col-sm-12">
                <textarea-autosize
                  :placeholder="$t('preview') + ' (EN)'"
                  :class="['form-control', valid_formData_preview_en ? 'is-valid' : 'is-invalid']"
                  v-model="formData.preview_en"
                  :min-height="100"
                  :max-height="350"
                />
              </div>
            </div>
          </CCol>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-12">
                {{ $t('content') + ' (EN)' }}
              </label>
              <div class="col-sm-12">
                <TinymceEdit :content="formData.content_en" @contentUpdate="content => formData.content_en = content" v-if="modal.show" />
              </div>
            </div>
          </CCol>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('banner')"
              :options="bannerOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="formData.banner"
              :isValid="valid_formData_banner"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('top')"
              :options="topOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="formData.top"
              :isValid="valid_formData_top"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('enable')"
              :options="enabledOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="formData.enabled"
              :isValid="valid_formData_enabled"
            />
          </CCol>
        </CRow>
      </CTab>
      <CTab title="ZH">
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('title') + ' (ZH)'"
              :placeholder="$t('title') + ' (ZH)'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="formData.title_zh"
              :isValid="valid_formData_title_zh"
            />
          </CCol>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-12">
                {{ $t('preview') + ' (ZH)' }}
              </label>
              <div class="col-sm-12">
                <textarea-autosize
                  :placeholder="$t('preview') + ' (ZH)'"
                  :class="['form-control', valid_formData_preview_zh ? 'is-valid' : 'is-invalid']"
                  v-model="formData.preview_zh"
                  :min-height="100"
                  :max-height="350"
                />
              </div>
            </div>
          </CCol>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-12">
                {{ $t('content') + ' (ZH)' }}
              </label>
              <div class="col-sm-12">
                <TinymceEdit :content="formData.content_zh" @contentUpdate="content => formData.content_zh = content" v-if="modal.show" />
              </div>
            </div>
          </CCol>
        </CRow>
      </CTab>
    </CTabs>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>

    <CropperModal :img="cropperModalData.img" :show="cropperModalData.modal" @showUpdate="show => cropperModalData.modal = show" @cropImage="cropImage" />
  </CModal>
</template>

<style>
  .img-cursor {
    cursor: pointer;
  }
</style>

<script>
import TinymceEdit from '@/views/tinymce/TinymceEdit'
import CropperModal from '@/views/cropper/CropperModal'

export default {
  name: 'CreatePostModal',
  components: {
    TinymceEdit,
    CropperModal
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      bannerOptions: [
        { value: 1, label: this.$t('yes') },
        { value: 0, label: this.$t('no'), }
      ],
      topOptions: [
        { value: 1, label: this.$t('yes') },
        { value: 0, label: this.$t('no'), }
      ],
      enabledOptions: [
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      formData: { content_en: '', content_zh: '' },
      cropperModalData: { img: '', modal: false },
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.formData = { image_url: null, title_en: '', preview_en: '', content_en: '', title_zh: '', preview_zh: '', content_zh: '', banner: 0, top: 0, enabled: 1 }
      }
    }
  },
  computed: {
    valid_formData_title_en () {
      return this.formData.title_en ? true : false
    },
    valid_formData_preview_en () {
      return this.formData.preview_en ? true : false
    },
    valid_formData_content_en () {
      return this.formData.content_en ? true : false
    },
    valid_formData_title_zh () {
      return this.formData.title_zh ? true : false
    },
    valid_formData_preview_zh () {
      return this.formData.preview_zh ? true : false
    },
    valid_formData_content_zh () {
      return this.formData.content_zh ? true : false
    },
    valid_formData_banner () {
      return this.formData.banner === 0 || this.formData.banner === 1 ? true : false
    },
    valid_formData_top () {
      return this.formData.top === 0 || this.formData.top === 1 ? true : false
    },
    valid_formData_enabled () {
      return this.formData.enabled === 0 || this.formData.enabled === 1 ? true : false
    },
    valid_submit () {
      return this.valid_formData_title_en && this.valid_formData_preview_en && this.valid_formData_content_en && this.valid_formData_title_zh && this.valid_formData_preview_zh && this.valid_formData_content_zh && this.valid_formData_banner && this.valid_formData_top && this.valid_formData_enabled
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('storePost', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('created_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    imageCropper (e) {
      const files = e.target.files
      if (!files || !files.length > 0) {
        //
      } else if (!/\.(png|jpeg|jpg)$/.test(files[0].name.toLowerCase()) || (files[0].type !== 'image/png' && files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg')) {
        this.$swal('Warning', '上傳檔案格式不正確，請上傳png, jpeg, jpg格式', 'warning')
      } else if (files[0].size > 10 * 1024 * 1024) {
        this.$swal('Warning', '上傳檔案大小超出上限，上限10MB。(檔案大小: ' + Math.round(files[0].size / 1024 / 1024).toString() + 'MB)', 'warning')
      } else {
        const loader = this.$loading.show()
        this.generateImgB64(files, b64 => {
          this.$refs.uploadImage.value = null
          this.cropperModalData.img = b64
          this.cropperModalData.modal = true
          loader.hide()
        })
      }
    },
    cropImage (blob, name) {
      const formData = new FormData()
      formData.append('file', blob, name)
      this.uploadFile(formData, filePath => {
        this.cropperModalData.img = ''
        this.cropperModalData.modal = false
        this.formData.image_url = filePath
      })
    },
    generateImgB64 (files, callback) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(files[0])
      fileReader.onload = (e) => {
        try {
          callback(e.target.result)
        } catch (e) {
          console.log(e)
          callback(false)
        }
      }
    },
    uploadFile (data, callback) {
      const loader = this.$loading.show()
      this.$store.dispatch('uploadFile', data).then(res => {
        callback(res[0].url)
        loader.hide()
      }).catch(e => {
        callback('')
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
  },
  beforeDestroy: function () {
  },
}
</script>
