<template>
  <editor
    api-key="8h3d59pat4v4vag8nn7d66m1plfzcs7l4gu3mhe6ffmhswvi"
    v-model="tinymce.content"
    :init="tinymce.init"
    @input="contentUpdate"
  />
</template>

<style>
  .tinymce-valid {
    border-color: #2eb85c !important;
  }
  .tinymce-invalid {
    border-color: #e55353 !important;
  }
  .swal2-container {
    z-index: 19891016 !important;
  }
</style>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'TinymceEdit',
  components: {
    'editor': Editor
  },
  props: {
    content: {
      type: String,
      required: true,
      default: ''
    },
  },
  data () {
    return {
      tinymce: {
        init: {
          language: this.$store.getters.locale === 'tw' ? 'zh_TW' : 'en',
          relative_urls: false,
          convert_urls: false,
          valid_elements : '*[*]',
          allow_html_in_named_anchor: true,
          width: '100%',
          height: 500, // '100%',
          min_height: 500,
          statusbar: false,
          menubar: false,
          toolbar: true,
          branding: false,
          plugins: [
            "advlist autolink link image lists charmap print preview hr anchor pagebreak",
            "searchreplace wordcount visualblocks visualchars codesample insertdatetime media nonbreaking",
            "save table directionality emoticons template paste code",
            // "autoresize"
          ],
          toolbar1: "undo redo | fontselect | fontsizeselect | bullist numlist outdent indent | codesample | emoticons",
          toolbar2: "print | styleselect | bold italic underline | forecolor backcolor table | alignleft aligncenter alignright alignjustify | link image media",
          fontsize_formats: '8=8pt 9=9pt 10=10pt 11=11pt 12=12pt 14=14pt 16=16pt 18=18pt 20=20pt 22=22pt 24=24pt 26=26pt 28=28pt 30=30pt 32=32pt 34=34pt 36=36pt',
          codesample_languages: [
            {text:"HTML",value:"html"},
            {text:"CSS",value:"css"},
            {text:"Javascript",value:"javascript"},
            {text:"Bash",value:"bash"},
            {text:"Batch",value:"batch"},
            {text:"C",value:"c"},
            {text:"C#",value:"csharp"},
            {text:"C++",value:"cpp"},
            {text:"Ruby",value:"ruby"},
            {text:"Git",value:"git"},
            {text:"Java",value:"java"},
            {text:"JSON",value:"json"},
            {text:"Markdown",value:"markdown"},
            {text:"Nginx",value:"nginx"},
            {text:"Perl",value:"perl"},
            {text:"PHP",value:"php"},
            {text:"Python",value:"python"},
          ],
          content_css: ['default', 'tinymce/css/prism.css'],
          file_picker_callback: this.filePickerCallback
        },
        content: this.content
      }
    }
  },
  watch: {
    content (e) {
      this.tinymce.content = e
      this.contentValid()
    }
  },
  mounted: function () {
    this.contentValid()
  },
  methods: {
    filePickerCallback (cb, value, meta) {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      if (meta.filetype === 'image') {
        input.setAttribute('accept', 'image/*')
      } else if (meta.filetype === 'media') {
        input.setAttribute('accept', 'video/*, audio/*')
      }
      input.onchange = e => {
        const file = e.target.files[0]
        if ((meta.filetype === 'image' && !/^(image)\//.test(file.type)) || (meta.filetype === 'media' && !/^(video|audio)\//.test(file.type))) {
          // alert(this.$t('incorrect_upload_file_format'))
          this.$swal('Warning', this.$t('incorrect_upload_file_format'), 'warning')
        } else if (file.size > 10 * 1024 * 1024) {
          this.$swal('Warning', '上傳檔案大小超出上限，上限10MB。(檔案大小: ' + Math.round(files[0].size / 1024 / 1024).toString() + 'MB)', 'warning')
        } else {
          const formData = new FormData()
          formData.append('file', file)
          this.uploadFile(formData, filePath => {
            cb(filePath, { title: file.name })
          })
        }
      }
      input.click()
    },
    uploadFile (data, callback) {
      const loader = this.$loading.show()
      this.$store.dispatch('uploadFile', data).then(res => {
        callback(res[0].url)
        loader.hide()
      }).catch(e => {
        callback('')
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    contentUpdate (e) {
      this.contentValid()
      this.$emit('contentUpdate', e)
    },
    contentValid () {
      const elValid = document.querySelector('.tinymce-valid')
      const elInvalid = document.querySelector('.tinymce-invalid')
      if (elValid) elValid.classList.remove('tinymce-valid')
      if (elInvalid) elInvalid.classList.remove('tinymce-invalid')
      if (this.tinymce.content) {
        document.querySelector('.tox-tinymce:not(.tox-tinymce--disabled)').classList.add('tinymce-valid')
      } else {
        document.querySelector('.tox-tinymce:not(.tox-tinymce--disabled)').classList.add('tinymce-invalid')
      }
    }
  },
  beforeDestroy: function () {
  }
}
</script>
