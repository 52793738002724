<template>
  <CModal
    :title="$t('image_crop')"
    size="lg"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12" style="height: 600px;">
        <vueCropper
          ref="cropper"
          :img="cropperOptions.img"
          :outputSize="cropperOptions.outputSize"
          :outputType="cropperOptions.outputType"
          :info="cropperOptions.info"
          :canScale="cropperOptions.canScale"
          :autoCrop="cropperOptions.autoCrop"
          :fixed="cropperOptions.fixed"
          :fixedNumber="cropperOptions.fixedNumber"
          :full="cropperOptions.full"
          :fixedBox="cropperOptions.fixedBox"
          :canMove="cropperOptions.canMove"
          :canMoveBox="cropperOptions.canMoveBox"
          :original="cropperOptions.original"
          :centerBox="cropperOptions.centerBox"
          :high="cropperOptions.high"
          :infoTrue="cropperOptions.infoTrue"
          :maxImgSize="cropperOptions.maxImgSize"
          :enlarge="cropperOptions.enlarge"
          :mode="cropperOptions.mode"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12" class="mt-2">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            
          </div>
          <CButtonGroup>
            <CButton color="info" v-bind="{ variant: 'outline' }" @click="cropperAction('rotateLeft')">向左旋轉90&deg;</CButton>
            <CButton color="info" v-bind="{ variant: 'outline' }" @click="cropperAction('rotateRight')">向右旋轉90&deg;</CButton>
          </CButtonGroup>
        </div>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="cropImage()">送出</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'CropperModal',
  components: {
  },
  props: {
    img: {
      type: String,
      required: true,
      default: ''
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      cropperOptions: {
        img: this.img,
        outputSize: 1,
        outputType: 'jpeg',
        info: true,
        canScale: true,
        autoCrop: true, //true
        fixed: false,
        fixedNumber: [1, 1],
        full: true,
        fixedBox: false,
        canMove: true,
        canMoveBox: true,
        original: false,
        centerBox: true,
        high: false,
        infoTrue: false,
        maxImgSize: 2000,
        enlarge: 1,
        mode: 'contain'
      },
      modal: {
        show: this.show
      }
    }
  },
  watch: {
    img (e) {
      this.cropperOptions.img = e
    },
    show (e) {
      this.modal.show = e
    }
  },
  mounted: function () {
  },
  methods: {
    cropperAction (action) {
      if (action === 'rotateLeft') {
        this.$refs.cropper.rotateLeft()
      } else if (action === 'rotateRight') {
        this.$refs.cropper.rotateRight()
      }
    },
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    cropImage () {
      this.$refs.cropper.getCropBlob(blob => {
        this.$emit('cropImage', blob, 'cropImage.jpg')
      })
    }
  },
  beforeDestroy: function () {
  }
}
</script>
